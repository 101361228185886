<template>
  <div>
    <NuxtLoadingIndicator />

    <AppPageTobBar />

    <AppMain>
      <slot />
    </AppMain>

    <AppPageFooter />

    <BaseNotifications />
  </div>
</template>